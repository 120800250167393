import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

const Reddalerts = () => {
  const [displayTech, toggleDisplayTech] = useState(false);
  const [displayDeployment, toggleDisplayDeployment] = useState(false);

  const displayTechList = () => {
    if (!displayTech) {
      return null;
    } else {
      return (
        <Fragment>
          <ul>
            <li>Javascript ES6</li>
            <li>CSS</li>
            <li>React</li>
            <ul>
              <li>React Hooks</li>
              <li>React Redux</li>
            </ul>
            <li>Node.js</li>
            <ul>
              <li>npm</li>
            </ul>
            <li>Python</li>
            <ul>
              <li>SQLAlchemy</li>
              <li>Flask Web Framework</li>
            </ul>
            <li>MySQL</li>
            <li>3rd party APIs</li>
            <ul>
              <li>Twilio API</li>
              <li>PRAW Reddit API</li>
            </ul>
          </ul>
        </Fragment>
      );
    }
  };

  const displayDeploymentList = () => {
    if (!displayDeployment) {
      return null;
    } else {
      return (
        <Fragment>
          <ul>
            <li>Domain and distributions:</li>
            <ul>
              <li>Domain registered through Porkbun.</li>
              <li>SSL Certificate generated by Amazon AWS ACM.</li>
              <li>DNS server configured through CloudFlare.</li>
              <li>Static assets hosted on AWS S3 buckets.</li>
              <li>Distributions set up with AWS CloudFront.</li>
            </ul>
            <li>Server, API routes, and Database:</li>
            <ul>
              <li>Flask server configured with CORS.</li>
              <li>
                Flask server with API routes deployed to AWS Elastic Beanstalk.
              </li>
              <li>
                Elastic Beanstalk environment configured with a load balancer.
              </li>
              <li>MySQL database hosted on AWS RDS.</li>
            </ul>
          </ul>
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <div className='projectPageContainer'>
        <h2 style={{ color: ' rgb(103, 197, 103)' }}>ReddAlerts</h2>

        <h3>
          An application for users to monitor their favorite Subreddits from the
          website, Reddit, based on specified keywords.
        </h3>

        <div className='sliderGroup'>
          <p className='prev' style={{ marginRight: '10px' }}>
            &#10094;{' '}
          </p>

          <div className='reddalerts-slider'>
            <div className='slide' id='reddalerts-slide-1'>
              <img
                src={require('../../images/reddalerts1.png')}
                alt='landingPage'
                className='reddalertsPhoto'
              ></img>
            </div>
            <div className='slide' id='reddalerts-slide-2'>
              <img
                src={require('../../images/reddalerts2.png')}
                alt='signUp'
                className='reddalertsPhoto'
              ></img>
            </div>
            <div className='slide' id='reddalerts-slide-3'>
              <img
                src={require('../../images/reddalerts3.png')}
                alt='settings'
                className='reddalertsPhoto'
              ></img>
            </div>
            <div className='slide' id='reddalerts-slide-4'>
              <img
                src={require('../../images/reddalerts4.png')}
                alt='dashboard'
                className='reddalertsPhoto'
              ></img>
            </div>
          </div>
          <p className='next' style={{ marginLeft: '10px' }}>
            {' '}
            &#10095;
          </p>
        </div>

        <div className='linkToProjectGroup'>
          <a
            href='https://reddalerts.com'
            target='_blank'
            rel='noopener noreferrer'
          >
            <text className='linkToProject'>Get the Full Experience</text>
          </a>
        </div>

        <hr style={{ width: '80%' }} />

        <div className='overview'>
          <h3 style={{ color: 'rgb(103, 197, 103)' }}>Overview:</h3>
          <ul>
            <li>
              Users can register for an account, that will be associated with
              their phone number.
            </li>
            <li>
              Users can specify different Subreddits, and corresponding
              Keywords, to monitor.
            </li>
            <li>
              When a monitored Subreddit has a rising post that contains a
              specified Keyword, the post's link is sent as a text message to
              the user.
            </li>
          </ul>

          <div className='technologyList'>
            <button
              type='input'
              onClick={() => toggleDisplayTech(!displayTech)}
              style={{ margin: '15px', color: 'rgb(103, 197, 103)' }}
            >
              Developed With
            </button>

            {displayTechList()}

            <button
              type='input'
              onClick={() => toggleDisplayDeployment(!displayDeployment)}
              style={{ margin: '15px', color: 'rgb(103, 197, 103)' }}
            >
              Deployed With
            </button>

            {displayDeploymentList()}
          </div>
        </div>

        <div className='linkGroup'>
          <Link to='/projects' className='link'>
            Projects
          </Link>
          <Link to='/about' className='link'>
            About Me
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default Reddalerts;
